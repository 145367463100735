import './style.scss'
import Page from '../../components/Page'
import Header from '../../components/Header'
import Content from '../../components/Content'
import MyCamps from '../../components/Camps/MyCamps'
import AllCamps from '../../components/Camps'
import Notifications from '../../components/Notifications'
import { signOut, useUser } from '../../providers/Auth/hooks'
import Divider from '../../components/Divider'
import { IonCol, IonGrid, IonRow, IonText, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useSpecialTalentsDashboardQuery } from '../../lib/apollo/types'
import Participant from '../../components/Camps/Participant'
import { useEffect } from 'react'
import Button from '../../components/Button'
import { logOutOutline } from 'ionicons/icons'
import { TRANSLATION_NS } from '../../lib/apollo/config'

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const { t: clubT } = useTranslation(TRANSLATION_NS)
  const user = useUser()
  const { push } = useIonRouter()

  const { data } = useSpecialTalentsDashboardQuery(
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  useEffect(() => {
    if (user && !user.user.roles.some((role) => role === 'trainer')) {
      push('/certificate', undefined, 'replace')
    }
  }, [push, user])

  return (
        <Page>
            <Header title={clubT('dashboard.title')} mainTitle>
                <Button icon={logOutOutline} onClick={async () => { await signOut() }} />
            </Header>
            <Content>
                <div className='home'>
                    <Notifications />
                    {(user?.user.given_name && (
                        <IonGrid>
                            <IonRow>
                                <h2>
                                    Hallo {user?.user.given_name}
                                </h2>
                            </IonRow>
                            <IonRow>
                                <IonText
                                    className='ion-margin-bottom n2br'
                                    color='medium'
                                >
                                    <span>
                                        {t('home.welcomeText')}
                                    </span>
                                </IonText>
                            </IonRow>
                        </IonGrid>
                    ))}
                    <MyCamps />
                    <AllCamps />
                    <Divider />
                    {/* <div className='camps__title'> */}
                    {/*    <h2>{t('dashboard.help.title')}</h2> */}
                    {/*    <IonText color='medium'>{t('dashboard.help.description')}</IonText> */}
                    {/* </div> */}
                    {/* <Buttons> */}
                    {/*    <Button onClick={() => Browser.open({ url: 'https://talent-engine.com/leitfaden/' })}> */}
                    {/*        {t('dashboard.help.button')} */}
                    {/*    </Button> */}
                    {/* </Buttons> */}
                    {(data?.specialTalentsDashboard.length ?? 0) > 0 && (
                        <>
                            <Divider />
                            <IonGrid>
                                <IonRow>
                                    <h2>
                                        {t('dashboard.specialTalents')}
                                    </h2>
                                </IonRow>
                                <IonRow>
                                    <IonText color='medium'>
                                        {t('dashboard.specialTalentsText')}
                                    </IonText>
                                </IonRow>
                                <IonRow className='dashboard__talents' >
                                    {data?.specialTalentsDashboard.map((talent) => (
                                        <IonCol key={talent.participant.id} className='dashboard__talents__item'>
                                            <Participant participant={talent.participant} campId={talent.eventId} />
                                        </IonCol>
                                    ))}
                                </IonRow>
                            </IonGrid>
                        </>
                    )}
                </div>
            </Content>
        </Page>
  )
}

export default Dashboard
